// Home.js
import React from 'react';
import P5Animation from './Background';
import { API_ON } from '../config.js';

function Home() {

    return (
        <div className="min-h-screen text-gray-300 p-8 flex items-center justify-center bg-opacity-0">
            <P5Animation />
            <h1 className="absolute z-10 top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-full  text-6xl font-semibold font-inconsolata"
                style={{ fontFamily: "'Inconsolata', monospace", color: "#ffc107", textShadow: "0 0 15px rgba(0, 255, 0, 0.5)" }}>
                <a href="https://twitter.com/Hypertonx">Hypertonx</a>
            </h1>
            <div className="absolute left-1/2 top-2/3 sm:top-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-y-4 gap-x-4 z-2 text-md font-curior-new"
                style={{ fontFamily: "'Inconsolata', monospace", color: "#ffc107", textShadow: "0 0 15px rgba(0, 255, 0, 0.5)" }}>
                <a className="flex items-center justify-center border rounded border-[#ffc107] px-2 py-1 hover:bg-[#ffc107] hover:bg-opacity-20" href="/ideas">Investment Track</a>

                {API_ON && (
                    <>
                        <a className="flex items-center justify-center border rounded border-[#ffc107] px-2 py-1 hover:bg-[#ffc107] hover:bg-opacity-20" href="/tools/cracks">PBF Cracks</a>
                        <a className="flex items-center justify-center border rounded border-[#ffc107] px-2 py-1 hover:bg-[#ffc107] hover:bg-opacity-20" href="/tools/AMR-buyback-history">AMR Buyback History</a>
                        <a className="flex items-center justify-center border rounded border-[#ffc107] px-2 py-1 hover:bg-[#ffc107] hover:bg-opacity-20" href="/tools/PBF-crack-spread-history">PBF Crack History</a>
                    </>
                )}

<a className="flex items-center justify-center border rounded border-[#ffc107] px-2 py-1 hover:bg-[#ffc107] hover:bg-opacity-20" href="/about">About</a>
            </div>
        </div>
    )
}

export default Home;