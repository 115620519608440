import React, { useState, useEffect } from 'react';
import axios from 'axios';
import GoogleSheetTable from '../Tools/GoogleSheetTable';
import LoadingSheet from './Loading';
import Header from './Header';
import Footer from '../Footer';
import {GO_API_URL} from '../../config.js';

function PBFValuationComp() {
  const [sheetData, setSheetData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios.get(`${GO_API_URL}/data/pbf-valuation-comps`)
      .then(response => {
        setSheetData(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('There was an error fetching the data: ', error);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="bg-[#212529]">
      <Header />
      {isLoading ? (
         <LoadingSheet/>
      ) : (
        <GoogleSheetTable title="PBF Valuation Comps" data={sheetData} />
      )}
      <Footer />
    </div>
  );
}

export default PBFValuationComp;