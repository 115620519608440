import React from 'react';
import P5Animation from '../Background';

function About() {
    return (
        <div className="min-h-screen text-gray-300 p-8 flex items-center justify-center bg-opacity-0">
            <P5Animation />
            <div className="z-10 flex flex-col items-center gap-y-8">
                <h1 className="text-6xl font-semibold font-inconsolata"
                    style={{ fontFamily: "'Inconsolata', monospace", color: "#ffc107", textShadow: "0 0 15px rgba(0, 255, 0, 0.5)" }}>
                    <a href="https://twitter.com/Hypertonx">Hypertonx</a>
                </h1>
                <div className="flex flex-col gap-y-4 gap-x-4 z-2 text-md font-curior-new lg:w-1/3 md:w-2/3 w-full p-4"
                    style={{ fontFamily: "'Inconsolata', monospace", color: "#ffc107", textShadow: "0 0 15px rgba(0, 255, 0, 0.5)" }}>
                    <p className="flex items-center justify-center border rounded border-[#ffc107] px-4 py-2 text-lg">Hypertonx is a concentrated value investor. They have read and vetting more than 12,000 L/S equity investment thesis published by hedge funds and analysts over the past decade.</p>
                    <a className="flex items-center justify-center border rounded border-[#ffc107] px-2 py-1 hover:bg-[#ffc107] hover:bg-opacity-20" href="/">Back Home</a>
                </div>
            </div>
        </div>
    )
}

export default About;