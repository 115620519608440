import React from 'react';

function Footer() {
    return (
        <div className="bg-[#212529] text-gray-300 p-8 flex justify-center items-center relative">
            <div style={{ fontFamily: "'Inconsolata', monospace", color: "#ffc107", textShadow: "0 0 15px rgba(0, 255, 0, 0.5)" }}>
                <span className="text-lg font-semibold font-inconsolata">&copy; {new Date().getFullYear()} Hypertonx</span>
            </div>

            <div className="ml-6" style={{ fontFamily: "'Inconsolata', monospace", color: "#ffc107", textShadow: "0 0 15px rgba(0, 255, 0, 0.5)" }}>
                <a className="px-4 py-1 hover:bg-[#ffc107] hover:bg-opacity-20 hover:rounded" href="/about">About</a>
            </div>
        </div>
    );
}

export default Footer;
