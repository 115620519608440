import React, { createContext, useContext, useEffect, useState } from 'react';
import { 
  GoogleAuthProvider, 
  TwitterAuthProvider,
  onAuthStateChanged,
  signInWithPopup, 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signOut, 
  sendPasswordResetEmail, 
  updateProfile 
} from 'firebase/auth';
import { auth } from './firebaseConfig.js';


const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

const handleUser = async (user) => {
  if (user) {
    let token = await user.getIdToken();
    return {
      id: user.uid, 
      email: user.email, 
      name: user.displayName, 
      token
    };
  } else {
    return null;
  }
};

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      handleUser(user).then(setCurrentUser).catch(setError);
    });
    return unsubscribe; // This serves as the cleanup.
  }, []);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    try {
      await signInWithPopup(auth, provider);
    } catch (err) {
      setError(err);
    }
  };

  const signInWithTwitter = async () => {
    const provider = new TwitterAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (err) {
      setError(err);
    }
  };

  const signIn = (email, password) => signInWithEmailAndPassword(auth, email, password).catch(setError);
  const signUp = (email, password) => createUserWithEmailAndPassword(auth, email, password).catch(setError);
  const signOutUser = () => signOut(auth).catch(setError);
  const resetPassword = (email) => sendPasswordResetEmail(auth, email).catch(setError);
  const updateProfileUser = (updates) => currentUser && updateProfile(currentUser, updates).catch(setError);

  const value = {
    currentUser,
    error,
    signInWithGoogle,
    signInWithTwitter,
    signIn,
    signUp,
    signOutUser,
    resetPassword,
    updateProfileUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}