import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import InvestmentIdeasTable from './Components/InvestmentIdeasTable2';
import Auth from './Components/Auth';
import { AuthProvider } from './firebase/AuthContext';
import Home from './Components/Home';
import About from './Components/Pages/About';
import AMRBuybackHistory from './Components/SheetPages/AMRBuybackHistory';
import PBFCrackHistory from './Components/SheetPages/PBFCrackHistory';
import PBFValuationComp from './Components/SheetPages/PBFValuationComp';
import CrackSpread from './Components/PBFCracks';
import MetCoalPriceHistoryChart from './Components/Pages/MetCoalPriceHistoryChart';

function App() {

  return (
    <AuthProvider>
      <Router>
        <div>
          <Routes>

            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />

            <Route path="/login" element={<Auth />} />
            <Route path="/ideas" element={<InvestmentIdeasTable />} />

            <Route path="/tools/cracks" element={<CrackSpread />} />
            <Route path="/tools/AMR-buyback-history" element={<AMRBuybackHistory />} />
            <Route path="/tools/PBF-crack-spread-history" element={<PBFCrackHistory />} />
            <Route path="/tools/PBF-valuation-comps" element={<PBFValuationComp />} />
            <Route path="/tools/met-coal" element={<MetCoalPriceHistoryChart />} />
            
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;