import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './SheetPages/Header.js';
import Footer from './Footer.js';
import {GO_API_URL} from '../config.js';

function CrackSpread() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [crackSpreadData, setCrackSpreadData] = useState([]);
  
    useEffect(() => {
      axios.get(`${GO_API_URL}/crackspread`)
        .then(response => {
          setCrackSpreadData(response.data);
        })
        .catch(error => {
          console.error('There was an error fetching the data: ', error);
        });
    }, []);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    function roundToTwoDecimals(num) {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }
    
    function formatDate(dateStr) {
        console.log("Date string:" + dateStr);
        const date = new Date(dateStr);
        return `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
    }

    return (
        <div className="bg-[#212529] text-white font-sans lg:h-screen md:h-screen flex flex-col overflow-x-auto">
            <Header />
            <div className={`drawer transition-transform transform ${drawerOpen ? 'translate-x-0' : '-translate-x-full'}`}>
                
                {[
                    { href: '/tools/amr-model', text: 'AMR Model' },
                ].map(link => (
                    <div className="link-card" onClick={() => window.location.href = link.href}>
                        {link.text}
                    </div>
                ))}
            </div>

            <div className="toggle-drawer" onClick={toggleDrawer}>
                <div className={`arrow ${drawerOpen ? 'down' : 'right'}`}></div>
            </div>


            <h1 className="text-center text-white font-bold mt-8 mb-8 text-4xl ">PBF Energy Crack Spread Estimates, Most Recent ANS Price</h1>

            <table className="crack-table mx-auto my-4 lg:w-1/3 sm:w-12/12  md:w-1/2 text-center border-separate space-y-3 text-md">
                <thead>
                    <tr className="font-bold">
                        <th className="border p-3 rounded-lg">{crackSpreadData[0] ? formatDate(crackSpreadData[0].date) : ""}</th>
                        <th className="border p-3 rounded-lg">Value</th>
                        <th className="border p-3 rounded-lg">Note</th>
                    </tr>
                </thead>
                <tbody>
                {crackSpreadData.map((record, index) => (
                <tr key={index}>
                  <td className="border p-3 rounded-lg">{record.name}</td>
                  <td className="border p-3 rounded-lg">${roundToTwoDecimals(record.value)}</td>
                  <td className="border p-3 rounded-lg text-yellow-400 text-xs">{record.note}</td>
                </tr>
                ))}
                </tbody>
            
            </table>
            <Footer />
        </div>
    );
}

export default CrackSpread;
