
import React from 'react';
import Sketch from 'react-p5';

const P5Animation = () => {
    let particles = [];  
    const num = 1000;
    const noiseScale = 0.01;

    const setup = (p5, canvasParentRef) => {
        p5.createCanvas(p5.windowWidth, p5.windowHeight).parent(canvasParentRef);
        for (let i = 0; i < num; i++) {
            particles.push(p5.createVector(p5.random(p5.width), p5.random(p5.height)));
        }
        p5.stroke(100);
    };

    const draw = p5 => {
        p5.background(0, 20);
        for (let i = 0; i < num; i++) {
            let p = particles[i];
            let s = 1
            p5.point(p.x, p.y);
            let n = p5.noise(p.x * noiseScale, p.y * noiseScale);
            let a = p5.TAU * n;
            p.x += p5.cos(a) * s;
            p.y += p5.sin(a) * s;
            if (!onScreen(p, p5)) {
                p.x = p5.random(p5.width);
                p.y = p5.random(p5.height);
            }
        }
    };

    const mouseReleased = (p5) => {
        p5.noiseSeed(p5.millis());
    }

    const onScreen = (v, p5) => {
        return v.x >= 0 && v.x <= p5.width && v.y >= 0 && v.y <= p5.height;
    };

    return (
        <Sketch setup={setup} draw={draw} mouseReleased={mouseReleased} style={{ position: 'absolute', top: 0, left: 0, zIndex: -1 }} />
    );
}

export default P5Animation;