import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig.js';
import { useAuth } from '../firebase/AuthContext.js';

function InvestmentIdeasTable() {
    const [investmentIdeas, setInvestmentIdeas] = useState([]);
    const [sortColumn, setSortColumn] = useState('tweet_date');
    const [sortDirection, setSortDirection] = useState('desc');
    const { currentUser, signOutUser } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!currentUser) {
            navigate('/login');
        }
    }, [currentUser, navigate])

    useEffect(() => {
        if (currentUser) {
            const fetchInvestmentIdeas = async () => {
                const investmentIdeasCollection = collection(db, 'investment_ideas');
                const q = query(investmentIdeasCollection, orderBy("tweet_date", "desc"));
                const investmentIdeasSnapshot = await getDocs(q);
                const ideas = investmentIdeasSnapshot.docs.map(doc => doc.data());
                setInvestmentIdeas(ideas);
            };

            fetchInvestmentIdeas();
        }
    }, [currentUser]);

    const sortedIdeas = [...investmentIdeas].sort((a, b) => {
        let compareA = a[sortColumn];
        let compareB = b[sortColumn];

        // If the sorting column is 'tweet_date', parse them as Dates
        if (sortColumn === 'tweet_date') {
            compareA = new Date(compareA);
            compareB = new Date(compareB);
        }

        if (compareA < compareB) return sortDirection === 'asc' ? -1 : 1;
        if (compareA > compareB) return sortDirection === 'asc' ? 1 : -1;
        return 0;
    });

    function floatToPercentString(floatValue) {
        return (floatValue * 100).toFixed(1) + '%';
    }

    function linkToTweet(tweetId) {
        return "https://twitter.com/u/status/" + tweetId;
    }
    /*
    function linkToTwitter(handle) {
        if (handle.startsWith("@")) {
            handle = handle.slice(1);
        }
        return 'https://twitter.com/' + handle;
    }
    */

    return (
        <div className="min-h-screen bg-gray-900 text-gray-200 text-md px-8 sm:px-1 md:px-4 lg:px-8 pt-4 pb-8 ">
            <div className="lg:flex md:flex sm:flex-row items-center justify-between mb-2 bg-gray-950 p-4 rounded-lg ">
                <div className="flex items-center sm:mb-4">
                    <h1 className="text-2xl font-bold text-gray-200 mr-4">Investment Ideas</h1> {/* Added marginRight (mr-4) for spacing */}
                    <a className="flex border rounded border-[#ffc107] px-2 py-1 hover:bg-[#ffc107] hover:bg-opacity-20 text-gray-200" href="/">Back</a>
                </div>
                <div className="flex items-center">
                    <label htmlFor="sortDropdown" className="mr-2 text-white">Sort by:</label>
                    <select
                        id="sortDropdown"
                        value={sortColumn}
                        onChange={(e) => {
                            const selectedSort = e.target.value;
                            if (selectedSort === sortColumn) {
                                setSortDirection(prevDirection => {
                                    return prevDirection === 'asc' ? 'desc' : 'asc';
                                });
                            } else {
                                if (selectedSort === 'performance' || selectedSort === 'tweet_date') {
                                    setSortDirection('desc');
                                } else {
                                    setSortDirection('asc');
                                }
                                setSortColumn(selectedSort);
                            }
                        }}
                        className="mr-2 bg-gray-800 text-white rounded border border-gray-700"
                    >
                        {/* Options here remain unchanged */}
                    </select>
                    <button
                        onClick={signOutUser}
                        className="bg-gray-950 px-2 py-1 border border-gray-400 rounded text-white hover:bg-[#ffc107] hover:bg-opacity-20 transition duration-200"
                    >
                        Logout
                    </button>
                </div>
            </div>
            <div className="overflow-x-auto md:overflow-x-visible text-green-600">
                <table className="min-w-full bg-gray-800 rounded-lg overflow-hidden shadow-lg">
                    <thead className="bg-gray-950 md:table-header-group hidden md:table-row-group">
                        <tr>
                            <th className="py-4 px-4 pl-6 text-left clickable" onClick={() => {
                                setSortColumn('company_name');
                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                            }}>Company</th>
                            <th className="py-4 px-4 text-left clickable" onClick={() => {
                                setSortColumn('stock_ticker');
                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                            }}>Ticker</th>
                            <th className="py-4 px-4 text-left clickable" onClick={() => {
                                setSortColumn('performance');
                                setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
                            }}>%</th>
                            <th className="py-4 px-4 text-left" onClick={() => {
                                setSortColumn('twitter_handle');
                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                            }}>Author</th>
                            <th className="py-4 px-4 text-left" onClick={() => {
                                setSortColumn('tweet_date');
                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                            }}>Publication Date</th>
                            <th className="py-4 px-6 text-left" onClick={() => {
                                setSortColumn('investment_style');
                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                            }}>Investment Idea Summary</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedIdeas.map((idea, index) => (
                            <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-800 ' : 'bg-gray-850'} border-y border-gray-700 hover:bg-gray-700 flex flex-col md:table-row`}>
                                <td className="py-4 px-4 font-bold">{idea.company_name}</td>
                                <td className="py-4 px-4 font-bold">{idea.stock_ticker}</td>
                                <td className="py-4 px-4 font-bold">{floatToPercentString(idea.performance)}</td>
                                <td className="py-4 px-4">
                                    <div>
                                        <div className='font-bold hover:underline'>
                                            <a href={linkToTweet(idea.tweet_id)} target="_blank" rel="noopener noreferrer">
                                                {idea.author_name}
                                            </a>
                                        </div>
                                        <div className="text-green-800 hover:underline">
                                            <a href={linkToTweet(idea.tweet_id)} target="_blank" rel="noopener noreferrer">
                                                {idea.twitter_handle}
                                            </a></div>
                                    </div>
                                </td>
                                <td className="py-4 px-4 text-gray-500">{idea.tweet_date}</td>
                                <td className="block w-full py-4 px-4 sm:px-1 md:px-2 lg:px-4 text-gray-300 p-8 md:table-cell">
                                    <div className="border border-gray-600 rounded p-2 sm:px-1 md:px-1 lg:px-2  mx-2 w-full">
                                        <div className="p-1 ">
                                            <div>{idea.investment_idea_summary}</div>
                                            <div className="mt-1 text-gray-500">{idea.investment_style}</div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default InvestmentIdeasTable;