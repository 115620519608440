import React from 'react';

function LoadingSheet({ title, data }) {
  return (
    <div className="bg-[#212529] text-white font-sans h-screen flex flex-col justify-center items-center">
      <div className="text-center"></div>
    </div>
  );
}

export default LoadingSheet;