import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

console.log(firebaseConfig);


const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

export { firebaseApp, auth, db };


/*

This results in 

{apiKey: undefined, authDomain: undefined, projectId: undefined, storageBucket: undefined, messagingSenderId: undefined, …}
apiKey
: 
undefined
appId
: 
undefined
authDomain
: 
undefined
measurementId
: 
undefined
messagingSenderId
: 
undefined
projectId
: 
undefined
storageBucket
: 
undefined
[[Prototype]]
: 
Object

Even though the .env file is in the root directory of the project, the variables are not being read.


*/

