import React from 'react';

function GoogleSheetTableWide({ title, data }) {
  if (!data || !data[0]) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-[#212529] text-white min-h-screen font-sans flex flex-col overflow-x-auto">
      <h1 className="text-center text-white font-bold mt-8 mb-8 text-4xl">{title}</h1>
      <div className="flex-1 flex items-center"> 
        <table className="mx-auto my-4 text-center border-separate space-y-3 text-sm">
          <thead>
            <tr className="font-bold">
              {data[0].map((header, index) => (
                <th key={index} className="border p-1">{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex} className="border p-1">{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

GoogleSheetTableWide.defaultProps = {
  data: [[]]
};

export default GoogleSheetTableWide;
