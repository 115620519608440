import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

function MetCoalPriceHistoryChart() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('https://hypertonx-go-gzwyxbry6q-uc.a.run.app/met-coal/price-history');
        const formattedData = response.data.map(item => ({
          date: new Date(item.date).toLocaleDateString(),
          price: item.price
        }));
        setData(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  const currentMonthPrice = data[data.length - 1]?.price;
  const currentMonthDate = data[data.length - 1]?.date;

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-black text-white">
      <div className="mb-4 p-4 border border-gray-700 rounded flex flex-col items-center">

        <span className="text-xl mb-2">SGX TSI FOB Australia Premium Coking Coal Futures, {currentMonthDate}:</span>
        <div className="p-2 my-4 border border-gray-500 rounded flex items-center justify-center h-10">
          <span className="text-2xl font-bold">{currentMonthPrice ? `$${currentMonthPrice.toFixed(2)}` : '-'}</span>
        </div>


      </div>
      <div className="w-full max-w-2xl">
        <LineChart
          width={600}
          height={325}
          data={data}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
          <Line type="monotone" dataKey="price" stroke="#8884d8" />
          <CartesianGrid stroke="#333" strokeDasharray="5 5" />
          <XAxis dataKey="date" stroke="#ccc" />
          <YAxis stroke="#ccc" />
          <Tooltip />
        </LineChart>
      </div>
    </div>
  );
}

export default MetCoalPriceHistoryChart;
