import React from 'react';
import { API_ON } from '../../config.js';

function Header() {
    return (
        <div className="bg-[#212529] text-gray-300 2xl:p-8 xl:p-8 lg:p-8 md:p-6 sm:p-2 p-1 relative flex flex-col sm:flex-row justify-between overflow-x-auto custom-scrollbar">
            <div className="mb-4 sm:mb-0 sm:absolute sm:top-4 sm:left-4 z-10">
                <h1 className="text-4xl font-semibold font-inconsolata" style={{ fontFamily: "'Inconsolata', monospace", color: "#ffc107", textShadow: "0 0 15px rgba(0, 255, 0, 0.5)" }}>
                    <a href="/">Hypertonx</a>
                </h1>
            </div>
            <div className="mt-4 p-0 mt-0 sm:mt-0 sm:absolute sm:top-6 sm:right-4 z-10 flex lg:gap-x-4 md:gap-x-4 sm:gap-x-2 font-curior-new" style={{ fontFamily: "'Inconsolata', monospace", color: "#ffc107", textShadow: "0 0 15px rgba(0, 255, 0, 0.5)" }}>
                <a className="border rounded border-[#ffc107] px-4 py-1 hover:bg-[#ffc107] hover:bg-opacity-20" href="/ideas">Investment Track</a>

                {API_ON && (
                    <>
                        <a className="border rounded border-[#ffc107] px-4 py-1 hover:bg-[#ffc107] hover:bg-opacity-20" href="/tools/cracks">PBF Cracks</a>
                        <a className="border rounded border-[#ffc107] px-4 py-1 hover:bg-[#ffc107] hover:bg-opacity-20" href="/tools/AMR-buyback-history">AMR Buyback History</a>
                        <a className="border rounded border-[#ffc107] px-4 py-1 hover:bg-[#ffc107] hover:bg-opacity-20" href="/tools/PBF-crack-spread-history">PBF Crack History</a>
                    </>
                )}
            </div>
        </div>
    );
}

export default Header;
