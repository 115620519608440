import React, { useState, useEffect } from 'react';
import axios from 'axios';
import GoogleSheetTableWide from '../Tools/GoogleSheetTableWide'; 
import LoadingSheet from './Loading';
import Header from './Header';
import Footer from '../Footer';
import {GO_API_URL} from '../../config.js';

function PBFCrackHistory() {
  const [sheetData, setSheetData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios.get(`${GO_API_URL}/data/pbf-crack-history`)
      .then(response => {
        setSheetData(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('There was an error fetching the data: ', error);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="bg-[#212529]">
      <Header />
      {isLoading ? (
         <LoadingSheet/>
      ) : (
        <GoogleSheetTableWide title="PBF Crack History" data={sheetData} />
      )}
       <Footer />
    </div>
  );
}

export default PBFCrackHistory;